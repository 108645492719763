
import { mapActions, mapGetters, mapState } from "vuex";
import Vue from "vue";
import { ModuleNames } from "@/store";
import {
  ShopCategory,
  ShopField,
  ShopProduct,
  ShopProductVariant,
} from "@/types/shop";
import Galleria from "primevue/galleria";
import ClarityButton from "@/components/clarity/ClarityButton.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCalendarAlt,
  faCubes,
  faCreditCard,
  faChevronRight,
  faChevronLeft,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatPrice,
  getDefaultSelectedVariant,
  getMinPrice,
  getProductItemPrice,
  getStandardProductItemPrice,
  getStockStatus,
  getStockStatusLabel,
} from "@/helpers/shop.helper";
import ClarityRadio from "@/components/clarity/ClarityRadio.vue";
import { UserRole } from "@/types/user";
import * as lodash from "lodash";
import ProductThumbnail from "@/components/ProductThumbnail.vue";
import VueSlickCarousel from "vue-slick-carousel";

export default Vue.extend({
  name: "ProductView",
  components: {
    ProductThumbnail,
    ClarityRadio,
    ClarityButton,
    Galleria,
    FontAwesomeIcon,
    VueSlickCarousel,
  },
  metaInfo(): { titleTemplate: string; meta: any } {
    return {
      titleTemplate:
        (this.basicProduct?.title ? this.basicProduct?.title + " | " : "") +
        this.companyName,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: (
            (this.basicProduct?.title ? this.basicProduct?.title + " | " : "") +
            this.description
          ).substring(0, 160),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            (this.basicProduct?.title ? this.basicProduct?.title + " | " : "") +
            this.companyName,
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            (this.basicProduct?.title ? this.basicProduct?.title + " | " : "") +
            this.description,
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content:
            (this.basicProduct?.title ? this.basicProduct?.title + " | " : "") +
            this.description,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${window.location.href}`,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.img,
        },
      ],
    };
  },

  data: function () {
    return {
      faTimesCircle,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      faChevronRight,
      faChevronLeft,
      variantDraft: {},
      faCalendarAlt,
      faCubes,
      faCreditCard,
      activeIndex: 0,
      displayCustom: false,
      isLoading: false,
      x: 50,
      y: 5,
    };
  },

  computed: {
    ...mapGetters(ModuleNames.shop, {
      shopProducts: "shopProducts",
      shopFields: "shopFields",
      flattenShopCategories: "flattenShopCategories",
      productsIsLoading: "productsIsLoading",
      shopProductsBasic: "shopProductsBasic",
      visibleShopProducts: "visibleShopProducts",
    }),
    ...mapState({ companyName: "companyName", description: "description" }),

    ...mapGetters(ModuleNames.auth, { authUser: "authUser" }),
    infos(): Array<{ text: string }> {
      const infos = [];

      infos.push({
        text: this.x + " osób obejrzało dzisiaj ten produkt",
      });
      if ((this.x + this.y) % 2 === 0) {
        infos.push({
          text: "Ktoś kupił w ciągu ostatniej godziny",
        });
      }
      infos.push({
        text: "Produkt kupiony " + this.y + " razy w ciągu ostatniego miesiąca",
      });
      return infos;
    },
    slideProducts(): Array<ShopProduct> {
      if (!this.product?.hasSlides && !this.product?.slides?.length) return [];
      return this.visibleShopProducts.filter((p: ShopProduct) =>
        this.product?.slides?.includes(p._id)
      );
    },
    basicProduct(): ShopProduct | undefined {
      return this.shopProductsBasic?.find(
        (product: ShopProduct) =>
          this.$route.params.id.split("-")?.[0] === product._id
      );
    },
    img(): string {
      let logo = this.basicProduct?.images?.[0]?.url;
      const defaultLogo = window.location.origin + "/logo2.png";
      if (logo && logo.startsWith("/")) logo = window.location.origin + logo;
      return logo ? logo : defaultLogo;
    },
    addToCartDisabled(): boolean {
      if (this.product?.isHidden) return true;
      if (this.selectedVariant && this.selectedVariant.quantity !== undefined)
        return this.selectedVariant.quantity === 0;
      return this.product?.quantity === 0;
    },
    productCategory(): ShopCategory | undefined {
      if (!this.product?.categories) return undefined;
      if (
        this.$route.query.kategoria &&
        this.product?.categories.includes(this.$route.query.kategoria as string)
      ) {
        return this.flattenShopCategories.find(
          (c: ShopCategory) => c._id === this.$route.query.kategoria
        );
      }
      return this.flattenShopCategories.find((c: ShopCategory) =>
        this.product?.categories.includes(c._id)
      );
    },
    productCategoryBreadcrumbs(): Array<{ title: string; value: string }> {
      const paths = this.productCategory?.path?.split("/");
      const idsPaths = this.productCategory?.idsPath?.split("/");
      if (paths)
        return paths.map((p, i) => ({
          title: p,
          value: idsPaths && idsPaths[i] ? idsPaths[i] : "",
        }));
      return [];
    },
    discount(): string {
      if (
        this.price !== undefined &&
        this.standardPrice !== undefined &&
        this.price !== this.standardPrice
      ) {
        return (
          "-" +
          Math.round((1 - this.price / this.standardPrice) * 10000) / 100 +
          "%"
        );
      }
      return "";
    },
    showEditButton(): boolean {
      return this.authUser?.roles?.includes(UserRole.SHOP_MODERATOR);
    },
    product(): ShopProduct | undefined {
      return this.shopProducts?.find(
        (product: ShopProduct) =>
          this.$route.params.id.split("-")?.[0] === product._id
      );
    },
    stockStatus(): number | undefined {
      if (!this.product) return undefined;
      let status = getStockStatus(
        this.selectedVariant ? this.selectedVariant : this.product
      );
      if (status === undefined) status = getStockStatus(this.product);
      return status;
    },
    stockStatusLabel(): string {
      let label = getStockStatusLabel(
        this.selectedVariant ? this.selectedVariant : this.product
      );
      if (!label) label = getStockStatusLabel(this.product);
      return label;
    },

    productFields(): Array<ShopField> {
      if (!this.product || !this.shopFields?.length) return [];
      return this.shopFields.filter((f: ShopField) =>
        this.product?.fields?.includes(f._id)
      );
    },
    price(): number | undefined {
      if (!this.product) return undefined;
      return getProductItemPrice(this.product, this.selectedVariant);
    },
    priceLabel(): string | undefined {
      if (!this.price) return undefined;
      return formatPrice(this.price);
    },
    standardPrice(): number | undefined {
      if (!this.product) return undefined;
      return getStandardProductItemPrice(this.product, this.selectedVariant);
    },
    standardPriceLabel(): string | undefined {
      if (!this.standardPrice) return undefined;
      return formatPrice(this.standardPrice);
    },
    minPrice(): string | undefined {
      if (this.product) {
        const price = getMinPrice(this.product, this.selectedVariant);
        if (price) return formatPrice(price);
      }
      return undefined;
    },
    productFieldsChangedPrice(): Array<ShopField> {
      if (!this.productFields?.length) return [];
      //todo: changePrice - to think about
      return this.productFields.filter((f: ShopField) => f.changePrice);
      // return this.productFields;
    },
    productFieldsNotChangedPrice(): Array<ShopField> {
      if (!this.productFields?.length) return [];
      //todo: changePrice - to think about
      return this.productFields.filter((f: ShopField) => !f.changePrice);
      // return this.productFields;
    },
    variantChangedPrice(): { [key: string]: string } | undefined {
      if (!this.variantDraft) return undefined;
      const keys = Object.keys(this.variantDraft).filter(
        (k) =>
          //todo: changePrice - to think about
          this.productFieldsChangedPrice.find(
            (p) => p.changePrice && p._id === k
          )
        // this.productFieldsChangedPrice.find((p) => p._id === k)
      );
      const res = {};
      keys.forEach((k) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        res[k] = this.variantDraft[k];
      });
      return res;
    },
    selectedVariant(): ShopProductVariant | undefined {
      return this.product?.variants?.find(
        (f) =>
          //todo: changePrice - to think about
          lodash.isEqual(f.fields, this.variantChangedPrice)
        // lodash.isEqual(f.fields, this.variantDraft)
      );
    },
  },
  created(): void {
    const randomIntFromInterval = (min = 5, max = 50) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    };
    this.x = randomIntFromInterval(50, 120);
    this.y = randomIntFromInterval(5, 15);
  },
  methods: {
    ...mapActions(ModuleNames.cart, ["addToCart"]),
    updateVariantDraft(title: string, value: string): void {
      Vue.set(this.variantDraft, title, value);
    },
    addProductToCart(event: Event): void {
      event.stopPropagation();
      if (this.product?.isHidden) {
        this.$noty.error(
          "Ten produkt jest niedostępny. Odśwież stronę i spróbuj ponownie"
        );
        return;
      }
      this.addToCart({
        product: { ...this.product },
        variantKeys: this.variantDraft ? { ...this.variantDraft } : undefined,
        variant: this.selectedVariant ? { ...this.selectedVariant } : undefined,
      });
    },
    imageClick(index: number): void {
      this.activeIndex = index;
      this.displayCustom = true;
    },
  },
  watch: {
    productFields: {
      immediate: true,
      handler(): void {
        this.variantDraft =
          { ...getDefaultSelectedVariant(this.product)?.fields } || {};
        if (
          this.productFields.length !== this.productFieldsChangedPrice.length
        ) {
          this.productFieldsNotChangedPrice.forEach((field) => {
            Vue.set(this.variantDraft, field._id, field.options?.[0]._id);
          });
        }
      },
    },
  },
});
